<template>
  <div justify="center">
    <a
      href="javascript:void(0);"
      :style="'text-decoration: underline' "
      @click="openForm"
    >{{ code }}</a>

    <v-dialog
      v-model="dialog"
      :height="dialogHeight"
      max-width="1100px"
      scrollable
    >
      <v-card tile>
        <overlays
          :is-absolute="true"
          :is-show="absoluteOverlayStatus"
        ></overlays>
        <v-toolbar
          class="d-flex justify-end"
          height="36px"
          color="#E1F5FE"
        >
          <v-btn
            icon
            @click="closeForm"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="overflow-y-auto"
          height="100%"
          tile
        >
          <template v-if="apierror.status == 'error'">
            <div v-for="msg in apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-container
            style="padding:24px 12px 12px;max-width:100% !important"
          >
            <v-row>
              <v-col cols="12" md="8" lg="7">
                <v-row>
                  <v-col cols="3" sm="2" style="padding-right: 0;">
                    <v-virtual-scroll :item-height="70" :items="items" height="350px" class="scroll1">
                      <template v-slot:default="{ item }">
                        <v-list-item class="pointer">
                          <v-img
                            :src="item.load"
                            height="67px"
                            max-width="67px"
                            @click="n = item.n"
                            @mouseover="n = item.n"
                          ></v-img>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-col>
                  <v-col cols="9" sm="10">
                    <div v-for="(item, i) in items" :key="i" class="d-flex justify-center align-center">
                      <img v-if="n === item.n" :src="item.load" style="width: 100%;" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4" lg="5">
                <div class="px-6" style="color:#2e2e22">
                  <v-spacer></v-spacer>
                  <div class="d-flex justify-end">
                    <Language>
                    </Language>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div>
                    <span style="font-size: 25px;font-weight: bold;f" class="font">{{ languagePOName(selectedPreorder) }}</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.Pellet') }}: {{ selectedPreorder.pellet_id }}</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.PreorderCode') }}: {{ selectedPreorder.po_code }}({{ selectedPreorder.po_id }})</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.Weight') }}: {{ formatData(selectedPreorder.po_weight) }} KG/F</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.PurchaseRange') }}: {{ formatData(selectedPreorder.po_max_qty) }}</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.PriceJPY') }}: ￥{{ formatData(selectedPreorder.po_price) }}/KG</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.PriceUSD') }}: ￥{{ formatData(selectedPreorder.po_price_usd) }}/ton</span>
                  </div>
                  <div style="font-size: 18px" class="my-3">
                    <span class="font">{{ $t('Preorder.Period') }}: {{ selectedPreorder.po_ssd }}〜{{ selectedPreorder.po_sed }}</span>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <div v-html="languageOvv(selectedPreorder)"></div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <div v-html="languageDesc(selectedPreorder)"></div>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import Overlays from '@/components/Overlays.vue'
import {
  useUtils,
} from '@/core/libs/i18n'
import Language from '@/components/language.vue'

export default {
  setup() {
    // i18n
    const { t } = useUtils()

    return {
      t,
    }
  },

  components: {
    Overlays,
    Language,
  },
  props: ['id', 'code'],
  data: () => ({
    dialog: false,
    images: [],
    selectedImg: '',
    selectedImgIndex: '',
    icons: {
      mdiEye,
      mdiClose,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    item: {
    },
    n: 1,
    items: [],
    language: '',
  }),

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('preorderStore', ['selectedPreorder']),
    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    languagePOName() {
      return function (selectedPreorder) {
        if (localStorage.getItem('lang') === 'zh') {
          return selectedPreorder.po_name_cn
        }
        if (localStorage.getItem('lang') === 'ja') {
          return selectedPreorder.po_name_ja
        }
        if (localStorage.getItem('lang') === 'en') {
          return selectedPreorder.po_name_en
        }

        return selectedPreorder
      }
    },
    languageDesc() {
      return function (selectedPreorder) {
        if (localStorage.getItem('lang') === 'zh') {
          return selectedPreorder.po_desc_cn
        }
        if (localStorage.getItem('lang') === 'ja') {
          return selectedPreorder.po_desc_ja
        }
        if (localStorage.getItem('lang') === 'en') {
          return selectedPreorder.po_desc_en
        }

        return selectedPreorder
      }
    },
    languageOvv() {
      return function (selectedPreorder) {
        if (localStorage.getItem('lang') === 'zh') {
          return selectedPreorder.po_ovv_cn
        }
        if (localStorage.getItem('lang') === 'ja') {
          return selectedPreorder.po_ovv_ja
        }
        if (localStorage.getItem('lang') === 'en') {
          return selectedPreorder.po_ovv_en
        }

        return selectedPreorder
      }
    },
  },

  watch: {
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),
    ...mapMutations('preorderStore', ['clearSelectedPreorder']),
    ...mapActions('preorderStore', ['loadPreorder']),
    closeForm() {
      this.dialog = false
      this.clearSelectedPreorder()
    },
    handleSelectedImg(imgUrl, index) {
      this.selectedImg = imgUrl
      this.selectedImgIndex = index
    },
    openForm() {
      this.setOverlayStatus(true)
      this.loadPreorder(this.id)
        .then(() => {
          if (this.selectedPreorder.imgUrl_prefix !== null) {
            this.selectedPreorder.image = this.selectedPreorder.images.split(',')
            this.selectedPreorder.image.forEach((item, i) => {
              const obj = {
                load: `${this.selectedPreorder.imgUrl_prefix}/${item.replaceAll('"', '').replace(/^\//g, '')}`, n: i + 1,
              }
              this.items.push(obj)
            })
          } else {
            const obj = {
              load: '/img/card-basic-influencer.889b9779.png', n: 1,
            }
            this.items.push(obj)
          }
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          console.log('sp', this.selectedPreorder)
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
  },
}
</script>

<style>
.v-card.on-hover {
  border-color: #e77600;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

.ImgArea {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.mainImgCard {
  /* height: 500px; */
  text-align: center;
}

.mainImg {
  object-fit: contain;
  /* height: 100%; */
  width: 100%;
}
</style>
