<template>
  <div id="reserved-list">
    <v-card>
      <v-card-title>承認済見積</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="pellet_id"
            :items="pelletIds"
            placeholder="Pellet"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="po_code"
            :items="preorderCodes"
            placeholder="予約商品"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="cust_abbr"
            :items="customerMaster"
            placeholder="取引先"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="quot_stat"
            :items="quotStatusMaster"
            placeholder="見積状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="12" md="2">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="予約日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="予約日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="dateEndMenu = false"></v-date-picker>
          </v-menu>
        </v-col> -->
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-4 me-3" color="primary" dark v-bind="attrs" v-on="on">
                <v-icon size="17" class="me-1">
                  {{ icons.mdiPlaylistEdit }}
                </v-icon>
                <span>一括操作</span>
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-item :disabled="OperationDisabled" @click="openOperationDialog"> -->
              <v-list-item :disabled="!bCanCreate" @click="openOperationDialog">
                <v-list-item-icon class="pr-1 ma-auto">
                  <v-icon :disabled="OperationDisabled">
                    {{ icons.mdiFileCheckOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex align-center">
                  <v-list-item-title>見積作成</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :disabled="!bCanFinalize" @click="openEditDialog">
                <v-list-item-icon class="pr-1 ma-auto">
                  <v-icon :disabled="!bCanFinalize">
                    {{ icons.mdiFileSendOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex align-center">
                  <v-list-item-title>見積決定</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :disabled="!bCanDelete" @click="openDeleteDialog">
                <v-list-item-icon :disabled="!bCanDelete" class="pr-1 ma-auto">
                  <v-icon>
                    {{ icons.mdiFileRemoveOutline }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-flex align-center">
                  <v-list-item-title>見積破棄</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        v-model="selectedReserveds"
        :headers="headers"
        :items="approvedList"
        item-key="resv_id"
        :search="searchQuery"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.resv_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushProgress(item)"
          >{{ item.resv_id }}</a>
        </template>
        <template v-slot:[`item.po_code`]="{ item }">
          <ProductPreview
            :id="item.po_id"
            :code="item.po_code"
            class="py-2 pl-1"
            @refresh="loadData"
          ></ProductPreview>
        </template>
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.resv_cnt`]="{ item }">
          {{ formatData(item.resv_cnt) }}
        </template>
        <template v-slot:[`item.resv_weight`]="{ item }">
          {{ formatData(item.resv_weight) }}
        </template>

        <!-- <template v-slot:[`item.quot_id`]="{ item }">
          <v-icon v-if="item.quot_id !== null" class="mr-2" @click="viewItem(item)">
            {{ icons.mdiFilePdfBox }}
          </v-icon>
        </template> -->
        <template v-slot:[`item.resv_amount`]="{ item }">
          {{ formatAmount(item.ccy_mark, item.resv_amount) }}
        </template>

        <template v-slot:[`item.quot_f_amount`]="{ item }">
          <span v-if="item.quot_f_amount>0">
            {{ formatAmount(item.ccy_mark, item.quot_f_amount) }}
          </span>
        </template>

        <!-- <template v-slot:[`item.resv_stat_desc`]="{ item }">
          <div class="d-flex justify-end">
            <v-chip style="width:65px" :color="getColor(item.resv_stat)" class="d-flex justify-center">
              {{ item.resv_stat_desc }}
            </v-chip>
          </div>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon v-if="item.quot_id !== null" class="mr-2" @click="viewItem(item)">
                {{ icons.mdiFilePdfBox }}
              </v-icon>
            </div>
            <div class="py-2 pl-1">
              <v-icon small :disabled="item.quot_id ===null" @click="editItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <approved-operation
      :dialog="operationDialog"
      :selected-reserveds="selectedReserveds"
      @closeForm="closeOperationDialog"
    ></approved-operation>
    <QuotationDelete
      :dialog="deleteDialog"
      :selected-reserveds="selectedReserveds"
      @closeForm="closeDeleteDialog"
    ></QuotationDelete>
    <QuotationFinalize
      :dialog="editDialog"
      :selected-reserveds="selectedReserveds"
      @closeForm="closeEditDialog"
    ></QuotationFinalize>
  </div>
</template>

<script>
import {
  mdiExportVariant,
  mdiPencil,
  mdiDelete,
  mdiFileCheckOutline,
  mdiFilePdfBox,
  mdiPlaylistEdit,
  mdiFileRemoveOutline,
  mdiFileSendOutline,
} from '@mdi/js'
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import ApprovedOperation from './ApprovedOperation.vue'
import ProductPreview from './ProductPreview.vue'
import QuotationDelete from './QuotationDelete.vue'

import QuotationFinalize from './QuotationFinalize.vue'

export default {
  components: {
    ApprovedOperation,
    ProductPreview,
    QuotationDelete,
    QuotationFinalize,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiFileCheckOutline,
      mdiPlaylistEdit,
      mdiFilePdfBox,
      mdiFileSendOutline,
      mdiFileRemoveOutline,
    },
    quot_stat: '',
    dialog: false,
    operationDialog: false,
    deleteDialog: false,
    editDialog: false,
    selectedReserveds: [],
    OperationDisabled: true,
    Destoryable: true,

    bCanCreate: false,
    bCanDelete: false,
    bCanFinalize: false,

    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    preorderCodes: [],
    po_code: '',
    reserve_id: '',
    cust_abbr: '',
    searchQuery: '',
    pellet_id: '',
    po_id: '',
    resv_stat: '',
    headers: [
      {
        text: 'ID.',
        align: 'left',
        fixed: true,
        value: 'resv_id',
        width: '8%',
      },
      {
        text: 'Pellet',
        align: 'left',
        value: 'pellet_id',
        fixed: true,
        width: '12%',
      },
      {
        text: '商品コード',
        value: 'po_code',
        align: 'left',

        width: '12%',
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '12%',
      },

      {
        text: '数量',
        value: 'resv_cnt',
        align: 'right',
        width: '6%',
      },

      {
        text: '総重量',
        value: 'resv_weight',

        align: 'right',
        width: '10%',
      },

      {
        text: '予約金額',
        value: 'resv_amount',

        align: 'right',
        width: '12%',
      },

      // {
      //   text: '割引金額',
      //   value: 'quot_d_amount',

      //   align: 'right',
      //   width: '10%',
      // },
      {
        text: '見積金額',
        value: 'quot_f_amount',

        align: 'right',
        width: '10%',
      },

      {
        text: '状態',
        value: 'quot_stat_desc',
        align: 'right',
        width: '10%',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '8%',
      },
    ],
  }),
  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('customerStore', ['customerMaster']),
    ...mapState('reservedStore', ['approvedList', 'resvStatusMaster']),
    ...mapState('quotationStore', ['quotStatusMaster']),

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  watch: {
    selectedReserveds(vals) {
      if (vals.length === 0) {
        this.bCanCreate = false
        this.bCanDelete = false
        this.bCanFinalize = false

        return
      }

      this.bCanCreate = true
      this.bCanDelete = true
      this.bCanFinalize = true

      console.log('count:', vals.length)
      for (let i = 0; i < vals.length; i += 1) {
        if (vals[i].quot_id !== null) {
          this.bCanCreate = false
        }
        if (vals[i].quot_id === null) {
          this.bCanFinalize = false
          this.bCanDelete = false
        }

        if (vals[i].resv_stat === 11) {
          this.bCanFinalize = false
        }
      }
    },

    pellet_id(val) {
      this.changeRouteQuery('pellet_id', val)
      this.loadData()
    },

    po_code(val) {
      this.changeRouteQuery('po_code', val)
      this.loadData()
    },

    cust_abbr(val) {
      this.changeRouteQuery('cust_id', val)
      this.loadData()
    },

    quot_stat(val) {
      console.log('quot_stat:', val)
      this.changeRouteQuery('quot_stat', val)
      this.loadData()
    },

    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.loadData()
    },
    endDate(val) {
      this.changeRouteQuery('endDate', val)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()

    this.loadData()
  },
  destoryed() {
    this.clearCache()
  },

  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('reservedStore', ['loadApprovedReservations', 'loadReservationStatus']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('reservedStore', ['clearReservedList', 'clearReservedStatus']),
    ...mapMutations('customerStore', ['clearCustomerMaster']),
    ...mapActions('quotationStore', ['loadQuotationStatus']),

    pushProgress(item) {
      this.$router.push({
        name: 'reserved-progress',
        params: {
          id: item.resv_id,
          from: 2,
        },
      })
    },

    toSendQuotaiton() {

    },

    openOperationDialog() {
      this.operationDialog = true
    },
    closeOperationDialog() {
      this.selectedReserveds = []
      this.operationDialog = false
      this.loadData()
    },
    openDeleteDialog() {
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.selectedReserveds = []
      this.deleteDialog = false
      this.loadData()
    },
    openEditDialog() {
      this.editDialog = true
    },
    closeEditDialog() {
      this.selectedReserveds = []
      this.editDialog = false
      this.loadData()
    },

    editItem(item) {
      console.log('approved edit item:', item)
      this.$router.push({
        name: 'approved-edit',
        params: {
          id: item.quot_id,
        },
      })
    },
    viewItem(item) {
      console.log('view', item)
      this.$router.push({
        name: 'approved-quotation',
        params: {
          id: item.quot_id,
        },
      })
    },
    clearCache() {
      this.clearReservedList()
      this.clearReservedStatus()
      this.clearCustomerMaster()
      this.clearPelletMaster()
    },
    loadQuery() {
      this.reserve_id = this.$route.query.reserve_id ?? ''
      this.po_code = this.$route.query.po_code ?? ''
      this.cust_abbr = this.$route.query.cust_abbr ?? ''
      this.quot_stat = this.$route.query.quot_stat ?? ''
      this.startDate = this.$route.query.start_date ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val || val === 0) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path,
        query,
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadPelletIds(),
        this.loadQuotationStatus(),
        this.loadApprovedReservations(this.$route.query),
      ])
        .then(() => {
          console.log('ApprovedList:', this.approvedList)
          this.preorderCodes = this.approvedList.map(ap => ap.po_code)
          console.log('PreCodes:', this.preorderCodes)
        })
        .catch(error => {
          console.log('loadData', error)
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },

    // getColor(status) {
    //   if (status === 1) {
    //     return 'primary'
    //   }
    //   if (status === 2) {
    //     return 'error'
    //   }
    //   if (status === 9) {
    //     return 'success'
    //   }

    //   return 'info'
    // },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
