var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reserved-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("承認済見積")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.pelletIds,"placeholder":"Pellet","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.pellet_id),callback:function ($$v) {_vm.pellet_id=$$v},expression:"pellet_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.preorderCodes,"placeholder":"予約商品","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.po_code),callback:function ($$v) {_vm.po_code=$$v},expression:"po_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.customerMaster,"placeholder":"取引先","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.cust_abbr),callback:function ($$v) {_vm.cust_abbr=$$v},expression:"cust_abbr"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.quotStatusMaster,"placeholder":"見積状態","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.quot_stat),callback:function ($$v) {_vm.quot_stat=$$v},expression:"quot_stat"}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4 me-3",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistEdit)+" ")]),_c('span',[_vm._v("一括操作")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.bCanCreate},on:{"click":_vm.openOperationDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto"},[_c('v-icon',{attrs:{"disabled":_vm.OperationDisabled}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileCheckOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積作成")])],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.bCanFinalize},on:{"click":_vm.openEditDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto"},[_c('v-icon',{attrs:{"disabled":!_vm.bCanFinalize}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileSendOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積決定")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"disabled":!_vm.bCanDelete},on:{"click":_vm.openDeleteDialog}},[_c('v-list-item-icon',{staticClass:"pr-1 ma-auto",attrs:{"disabled":!_vm.bCanDelete}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileRemoveOutline)+" ")])],1),_c('v-list-item-content',{staticClass:"d-flex align-center"},[_c('v-list-item-title',[_vm._v("見積破棄")])],1)],1)],1)],1),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.approvedList,"item-key":"resv_id","search":_vm.searchQuery,"show-select":""},scopedSlots:_vm._u([{key:"item.resv_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.pushProgress(item)}}},[_vm._v(_vm._s(item.resv_id))])]}},{key:"item.po_code",fn:function(ref){
var item = ref.item;
return [_c('ProductPreview',{staticClass:"py-2 pl-1",attrs:{"id":item.po_id,"code":item.po_code},on:{"refresh":_vm.loadData}})]}},{key:"item.cust_abbr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:('text-decoration: underline; color:#666666')},'a',attrs,false),on),[_vm._v(_vm._s(item.cust_abbr))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cust_id))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name_eng))]),_c('br')])]}},{key:"item.resv_cnt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.resv_cnt))+" ")]}},{key:"item.resv_weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.resv_weight))+" ")]}},{key:"item.resv_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAmount(item.ccy_mark, item.resv_amount))+" ")]}},{key:"item.quot_f_amount",fn:function(ref){
var item = ref.item;
return [(item.quot_f_amount>0)?_c('span',[_vm._v(" "+_vm._s(_vm.formatAmount(item.ccy_mark, item.quot_f_amount))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"py-2 pl-1"},[(item.quot_id !== null)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePdfBox)+" ")]):_vm._e()],1),_c('div',{staticClass:"py-2 pl-1"},[_c('v-icon',{attrs:{"small":"","disabled":item.quot_id ===null},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)])]}}],null,true),model:{value:(_vm.selectedReserveds),callback:function ($$v) {_vm.selectedReserveds=$$v},expression:"selectedReserveds"}})],1),_c('approved-operation',{attrs:{"dialog":_vm.operationDialog,"selected-reserveds":_vm.selectedReserveds},on:{"closeForm":_vm.closeOperationDialog}}),_c('QuotationDelete',{attrs:{"dialog":_vm.deleteDialog,"selected-reserveds":_vm.selectedReserveds},on:{"closeForm":_vm.closeDeleteDialog}}),_c('QuotationFinalize',{attrs:{"dialog":_vm.editDialog,"selected-reserveds":_vm.selectedReserveds},on:{"closeForm":_vm.closeEditDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }